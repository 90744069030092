.c-button {
  width: 190px;
  height: 52px;
  border: 1px solid #207CD3;
  box-shadow: 0px 3px 9px 0px rgba(41,70,167,0.2);
  border-radius: 26px;
  font-size: 0.2rem;
  text-align: center;
  outline: none;
  text-decoration: none;
  cursor: pointer;

  &--default {
    color: #207CD3;
    background: #ffffff;
  }

  &--primary {
    color: #ffffff;
    background: #207CD3;
  }
}

@media screen 
and (min-width: 300px)
and (max-width: 1023px) {
  .c-button {
    width: 164px;
    font-size: 0.22rem;
  }
}
.kz-logo {
  height: 100%;
  margin-right: 10%;
  display: flex;
  align-items: center;

  &__image {
    max-height: 100%;
    max-width: 100%;
  }
}

@media screen 
  and (min-width: 320px) 
  and (max-width: 1025px) {
    .kz-logo {
      margin-right: 0.6rem;
    }
  }
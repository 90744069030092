.km-squeeze-container {
  width: 100%;
  box-sizing: border-box;

  &__container {
    padding: 0 2rem;
  }

  &__function-wrap {
    margin: 0 auto;
    width: 85%;
  }

  &__title-wrap {
    margin: 0 auto;
    padding: 0.4rem 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &-logo {
      margin-right: 0.2rem;
      width: 0.8rem;
      height: 0.8rem;
    }

    &-title {
      font-size: 0.5rem;
      font-weight: normal;
      color: #2f4860;
    }
  }

  &__input-label {
    margin-bottom: 10px;
    font-size: 20px;
    color: #252a4b;
  }

  &__input-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &-input {
      margin-right: 20px;
      height: 42px;
      border-color: #456bfe;
      font-size: 18px;
    }

    &-button {
      width: 163px;
      height: 42px;
      background-color: #456bfe;
    }
  }

  &__short-url-wrap {
    width: fit-content;
    text-align: center;
    position: relative;
    line-height: 20px;
    margin: 30px auto 10px;
    font-size: 20px;
    font-weight: 500;
    padding: 14px 20px;
    transform: scale(0);
    transition: all 0.2s ease;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 0;
      height: 0;
      border: none;
      transition: all 0.5s ease;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      right: 50%;
      bottom: 50%;
      width: 0;
      height: 0;
      border: none;
      transition: all 0.5s ease;
    }

    &.hasurl {
      transform: scale(1);
      &::before {
        content: "";
        display: block;
        width: 30%;
        height: 20px;
        position: absolute;
        left: 0;
        top: 0;
        border-top: 2px solid #456bfe;
        border-left: 2px solid #456bfe;
        border-bottom: 2px solid transparent;
        border-right: 2px solid transparent;
      }

      &::after {
        content: "";
        display: block;
        width: 30%;
        height: 20px;
        position: absolute;
        right: 0px;
        bottom: 0px;
        border-bottom: 2px solid #456bfe;
        border-right: 2px solid #456bfe;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
      }
    }
  }

  &__footer {
    width: 100%;
  }

  &__tip-wrap {
    width: 100%;
    background-color: #efeff2;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    padding: 0 10px;
    box-sizing: border-box;
  }

  &__tip {
    color: red;
    text-align: center;
    padding: 7px 0;
  }

  &__support {
    text-decoration: underline;
    color: #0033f8;
    padding-bottom: 7px;
  }
}

@media screen and (min-width: 300px) and (max-width: 1023px) {
  .km-squeeze-container {
    &__container {
      padding: 0 10px;
    }

    &__function-wrap {
      margin: 0 auto;
      width: 100%;
    }

    &__input-label {
      margin-bottom: 10px;
      font-size: 14px;
      color: #252a4b;
    }

    &__input-wrap {
      &-input {
        height: 30px;
        font-size: 12px;
      }

      &-button {
        flex-shrink: 0;
        width: unset;
        height: 30px;
      }
    }

    &__radio {
      max-height: 60px;
      overflow: hidden;
    }
  }
}

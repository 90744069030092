@import '../../assets/styles/variable.less';
@import '../../assets/styles/extends.less';

.cy-logo:extend(.v-center) {
  color: @border-black;
  height: fit-content;
  // margin-right: 0.71rem;
  font-size: 0.2rem;
  font-weight: bold;
  
  & .header-logo  {
    max-width: 100%;
    height: auto;
  }

  & .bg-img {
    width: 100%;
    height: 100%;
    background-image: url(../../assets/images/big-screen-logo.png);
    background-repeat: no-repeat;
    background-size: contain;
  }
}

@media screen 
  and (min-width: 320px) 
  and (max-width: 955px) {
    .cy-logo {
      & .bg-img {
        background-image: url(../../assets/images/small-screen-logo.png) ;
      }

      & .header-logo {
        width: 72%;
      }
    }
  }
.layout-footer {
  width: 100%;
  text-align: center;
  color: #ABABAB;
  font-size: 0.12rem;
  padding: 0.23rem 0.1rem;
  background: #262626;
  word-break: keep-all;

  & > a {
    color: white;
    text-decoration: none;
    font-weight: bold;
  }
}

@media screen 
  and (min-width: 320px) 
  and (max-width: 800px) {
    .layout-footer {
      font-size: 0.18rem;
    }
  }
.c-banner-carousel {
  width: 100%; // 宽度取决于父元素
  height: 100%;
  overflow: hidden;

  &__view-window {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  &__item-wrap {
    display: flex;
    position: relative;
    -webkit-transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }

  &__item-img {
    width: 100%;
  }

  &__btn {
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(50, 85, 232, 1);
    opacity: 0.55;
    text-align: center;
    line-height: 50px;
    color: #fff;

    & .iconfont {
      font-size: 20px;
    }

    &.pre {
      left: 0;
    }

    &.next {
      right: 0;
    }
  }

  &__dot {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5%;
    justify-content: space-between;

    &-item {
      width: 15px;
      height: 15px;
      background: #e8f2fb;
      border-radius: 50%;
      margin-right: 15px;

      &.active {
        background: #3255e8;
      }
    }
  }
}

@media screen
and (min-width: 300px)
and (max-width: 1023px) {
  .c-banner-carousel {

    &__dot {
      
      &-item {
        width: 10px;
        height: 10px;
        margin-right: 5px;
      }
    }
  }
}
.kz-ui-drawer { // 默认关闭状态
  & * {
    transition: transform .3s cubic-bezier(.7,.3,.1,1),box-shadow .3s cubic-bezier(.7,.3,.1,1),-webkit-transform .3s cubic-bezier(.7,.3,.1,1),-webkit-box-shadow .3s cubic-bezier(.7,.3,.1,1);
  }

  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 0vw;
  z-index: 1000;
  transition: transform .3s cubic-bezier(.7,.3,.1,1),height 0s ease .3s,width 0s ease .3s,-webkit-transform .3s cubic-bezier(.7,.3,.1,1);


  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.45);
    opacity: 0;
    filter: alpha(opacity=45);
    -webkit-transition: opacity .3s linear,height 0s ease .3s;
    transition: opacity .3s linear,height 0s ease .3s;
  }

  &__content {
    position: relative;
    z-index: 1;
    overflow: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    width: 100%;
    height: 100%;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;

    &-wrapper {
      height: 0;
      right: 0;
      position: absolute;
      width: 269px;
      box-shadow: -6px 0 16px -8px rgba(0,0,0,.08), -9px 0 28px 0 rgba(0,0,0,.05), -12px 0 48px 16px rgba(0,0,0,.03);
      transform: translateX(100%);
    }
  }

  &__header {
    position: relative;
    padding: 19px 24px;
    color: rgba(0,0,0,.65);
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
    color: rgba(0,0,0,.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }

  &__body {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 19px 42px;
    overflow: auto;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
  }


  &__show {  // 打开状态
    transition: transform .3s cubic-bezier(.7,.3,.1,1),-webkit-transform .3s cubic-bezier(.7,.3,.1,1);
    width: 100vw;

    & .kz-ui-drawer__mask {
      opacity: 1;
    }

    & .kz-ui-drawer__content-wrapper {
      transform: translateX(0);
      height: 100%;
    }
  }
}
.km-introduce {
  width: 100%;
  box-sizing: border-box;
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto;

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 36px;

    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 166px;
      background-color: #EFEFF2;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    @media screen and (min-width: 1023px) {
      &:nth-of-type(n + 4) {
        border-top: 1px solid #EFEFF2;
      }
  
      &:nth-of-type(3n) {
        &::after{
          content: none;
        }
      }
    }

    &-img {
      width: 62px;
      height: 62px;
      margin-bottom: 25px;
    }

    &-title {
      font-size: 21px;
      font-weight: bold;
      margin-bottom: 25px;
    }

    &-desc > span {
      text-align: center;
      font-size: 14px;
      color: #666666;
      display: inline-block;
      line-height: 25px;

      br {
        margin-bottom: 5px;
      }
    }
  }
}

@media screen 
and (min-width: 300px)
and (max-width: 1023px) {
  .km-introduce {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    padding-top: 20px;

    &__item {
      padding: 10px 5px 20px;

      &:nth-of-type(n + 3) {
        border-top: 1px solid #EFEFF2;
      }
  
      &:nth-of-type(2n) {
        &::after{
          content: none;
        }
      }

      &-img {
        margin-bottom: 15px;
      }

      &-title {
        margin-bottom: 15px;
        font-size: 18px;
      }

      &-desc > span {
        font-size: 12px;
      }
    }
  }
}
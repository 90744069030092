@import '../../assets/styles/variable.less';
@import '../../assets/styles/extends.less';

body {
  background-color: @white;
}

.layout-header {
  background-color: @white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 0 0;
  position: fixed;
  top: 0;
  z-index: 800;
  box-shadow:0px 0px 10px 0px rgba(3,3,3,0.13);


  &__links-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 3.4rem;
    background-color: @white;
    margin-top: 4px;

    & a {
      color: #000000;
      font-weight: 300;
      vertical-align: middle;
      font-size: 0.16rem;
      margin-top: -2px;

      &:hover {
        color: @main-blue;
        font-weight: bold;
      }
    }

    & .link {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      &-new {
        &::after {
          content: '';
        //   color: #ffffff;
          background-color: #d42404;
          position: absolute;
        //   font-size: 12px;
          font-weight: bold;
          height: 9px;
          width: 9px;
        //   line-height: 18px;
        //   padding: 0 7px;
          top: -7px;
          right: -7px;
          border-radius: 4px;
        }
      }

      & .current-layout {
        color: @main-blue;
        font-weight: bold;
      }

      & .selected {
        height: 2px;
        width: 100%;
        background-color: @main-blue;
        margin-top: 4px;
      }

      & .unselected {
        height: 2px;
        width: 100%;
        background-color: @white;
      }
    } 
  }

  &__user-info {
    &-links:extend(.r-space-between) {
      align-items: center;
      width: 183px;
      font-size: 12px;

      & .btn-link {
        background: @main-blue;
        color: @white;
        width: 88px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        font-weight: 400;
        font-family: @font-two;
        cursor: pointer;
      }
    }

    &-data:extend(.r-space-between) {
      align-items: center;
      width: 176px;
      font-size: 16px;
    }

    & .text-link:extend(.r-space-between) {
      &:extend(.v-center);
      cursor: pointer;
      &:hover {
        color: @main-blue;
      }
    }
  }

  &__logo:extend(.v-center) {
    margin-right: 0.71rem;
  }  
}

@media screen  
  and (min-width: 320px) 
  and (max-width: 830px) {
  .cy-layout {
    padding-top: 86px;
  }

  .cy-header {
    height: 50px;
    padding: 0 9px;

    &__logo {
      margin-right: 0;
    }  

    &__left-links {
      justify-content: flex-start;
      position: absolute;
      left: 0; 
      height: 36px;
      width: 100%;
      margin-top: 42px;
      padding-left: 9px;
      border-top: 1px solid #DADADA;
      box-shadow:0px 5px 6px 0px rgba(3,3,3,0.13);

      & .link {
        margin-right: 36px;
        
        & a {
          font-size: 0.2rem;
          margin-top: 0;
        }
      }


      & .selected, & .unselected {
        display: none;
      }

    }

    &__right {
      &-infos {
        width: 152px;

        & .icon-close {
          display: none;
        }
      }
    }
  }
}




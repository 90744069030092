.km-logo {
  height: 50px;
  margin-right: 10%;
  display: flex;
  align-items: center;
  cursor: pointer;

  &__image {
    height: 100%;
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    flex-shrink: 0;
    padding-left: 12px;
    color: #284761;
  }
}

@media screen 
  and (min-width: 320px) 
  and (max-width: 1025px) {
    .km-logo {
      height: 36px;
      margin-right: 0.6rem;

      &__title {
        font-size: 20px;
      }
    }
  }
.km-our-info {
  &__infomation {
    padding-bottom: 0.12rem;
  }

  &__infomation .our-infomation-container {
    width: 90%;
  }
}

@media screen and (min-width: 300px) and (max-width: 1203px) {
  .km-our-info {
    &__infomation {
      display: none;
    }
  }
}

.c-ks-promotion {
  position: fixed;
  bottom: 0;
  z-index: 10001;
  background-color: white;
  width: 96%;
  height: 80px;
  border-radius: 25px;
  margin: 10px 2%;
  box-shadow: 0 0 20px 20px rgba(102, 102, 102, 0.5);

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  &__pic {
    width: 60px;
    height: 60px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }

  &__content-title {
    font-size: 22px;
    color: #000000;
    line-height: 32px;
  }

  &__content-desc {
    font-size: 15px;
    color: #6C6C6A;
    line-height: 25px;
  }

  &__download-btn {
    background: #FEA84A;
    border-radius: 5px;
    padding: 0 6px;

    font-size: 13px;
    color: #FFFFFF;
    line-height: 30px;
  }
  
}
@import '../../assets/styles/extends.less';
@import '../../assets/styles/variable.less';
.calling-card:extend(.column) {
  @media screen  
    and (min-width: 320px) 
    and (max-width: 830px) {
      margin-bottom: 0.46rem;
  }
  &__title:extend(.v-center) {
    color: @white;
    font-size: 18px;
    font-family: @font-two;
    margin-bottom: 25px;

    & .vertical-line {
      width: 2px;
      height: 17px;
      background-color: #428CFF;
      margin-right: 10px;
    }
  }

  &__cut {
    height: 2px;
    width: 268px;
    background-color: #262626;
  }

  &__content {
    font-size: 12px;
    color: #b3b3b3;
    line-height: 2;
    @media screen  
      and (min-width: 320px) 
      and (max-width: 830px) {
        font-size: 15px;
    }

  }
}
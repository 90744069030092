@import './variable.less';

.flex {
  display: flex;
}

.column:extend(.flex) {
  flex-direction: column;
}

.h-v-center:extend(.flex) {
  justify-content: center;
  align-items: center;
}

.c-h-v-center:extend(.h-v-center) {
  &:extend(.column);
}

.h-center:extend(.flex) {
  justify-content: center;
}

.v-center:extend(.flex) {
  align-items: center;
}

.c-v-center:extend(.column) {
  &:extend(.h-center);
}

.c-h-center:extend(.column) {
  &:extend(.v-center);
}

.r-space-between:extend(.flex) {
  justify-content: space-between;
}

.c-space-between:extend(.column) {
  justify-content: space-between;
}

.flex-wrap:extend(.flex) {
  flex-wrap: wrap;
}

.register-button--big:extend(.h-v-center) {
  border: 2px solid @white;
  width: 2.22rem;
  height: 0.56rem;
  font-size: 0.18rem;
  font-weight: bold;
  border-radius: 0.28rem;
  @media screen  
    and (min-width: 320px) 
    and (max-width: 830px) {
    width: 2.6rem;
    font-size: 0.22rem;
  }
}

.register-button--small:extend(.h-v-center) {
  border: 2px solid @main-blue;
  color: @main-blue;
  width: 1.46rem;
  height: 0.46rem;
  font-size: 0.16rem;
  font-weight: bold;
  border-radius: 0.28rem;
  margin-left: 0.16rem;
}

.hover-float {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hover-grow {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
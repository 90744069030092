@import '../../assets/styles/extends.less';
@import '../../assets/styles/variable.less';

.our-infomation:extend(.h-v-center) {
  width: 100%;
  background-color: #383838;
  color: #B3B3B3;
  padding: 0.58rem 0 0.92rem 0; 
  font-family: @font-two;
  font-size: 0.16rem;

  &-container:extend(.r-space-between) {
    width: 920px;

    & .qrcode-container:extend(.h-v-center) {
      width: 84px;
      height: 84px;
      background: @white;
      border-radius: 6px;

      & img {
        width: 97%;
        height: auto;
        border-radius: 6px;
      }
    }
  }

  &__logo-part:extend(.c-v-center) {
    & .cy-logo {
      width: 100%;
      height: 29.2%;
      margin-bottom: 0.17rem;
    }
  }
}

@media screen 
  and (min-width: 320px) 
  and (max-width: 955px) {
    .our-infomation {
      &-container {
        width: 100%;
        flex-direction: column;
        align-items: center;
        font-size: 15px;
      }
       
      &__logo-part {
        align-items: center;

        & .cy-logo {
          width: 74.42%;
          height: 100px;
        }
      }
    }
  }
@import '../../assets/styles/variable.less';
@import '../../assets/styles/extends.less';

.km-layout {
  font-family: @font-one;
  padding-top: 84px;

  &__header.layout-header {
    height: 84px;
    padding: 0 5.9%;
    box-shadow: 0px 3px 12px 1px rgba(0, 0, 0, 0.06);
  }

  &__left {
    &:extend(.v-center);

    & .intruction-entrance {
      height: 60px;
      display: flex;
      align-items: center;
      margin-left: 42px;

      &-img {
        height: 100%;
        width: auto;
      }
    }
  }

  &__nav.layout-header__links-nav {
    width: fit-content;

    & .link {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 0.32rem;
      white-space: nowrap;

      & a {
        &:hover {
          color: #198ed9;
          font-weight: bold;
        }
      }

      & .current-layout {
        color: #198ed9;
        font-weight: bold;
      }

      & .selected {
        height: 2px;
        width: 100%;
        background-color: #198ed9 !important;
        margin-top: 4px;
      }
    }
  }

  &__right:extend(.v-center) {
    justify-content: space-between;

    & .login-buttons {
      &:extend(.v-center);
      justify-content: flex-end;

      & .login-a {
        @media screen and (max-width: 750px) {
          display: none;
        }
        color: #115be8;
        padding-right: 20px;

        &:hover {
          color: #115be8;
        }
      }
      & .login-btn,
      & .register-btn {
        width: auto;
        height: auto;
        border-width: 1px;
        font-size: 0.16rem;
      }

      & .login-btn {
        padding: 0.08rem 0.34rem;
        margin-right: 0.1rem;
      }

      & .register-btn {
        padding: 0.08rem 0.22rem;
      }
    }

    & .wap-menu {
      display: none;
    }

    & .user-info {
      display: flex;
      align-items: center;

      & .info-unit {
        margin-right: 16px;
        font-size: 0.14rem;
        color: #4c4c4c;

        &:nth-child(2),
        &:nth-child(3),
        &:last-child {
          &:hover {
            color: #198ed9;
          }
        }

        &:last-child {
          margin-right: 0;
          cursor: pointer;
        }
      }
    }
  }

  &__dialog {
    padding: 0.23rem 0.32rem 0.68rem 0.31rem;

    .kui-dialog-header {
      padding: 0;
      padding-bottom: 0.55rem;
      height: 0;
      font-size: 0.2rem;
      color: #252a4b;

      i {
        margin: 0;
      }
    }

    .kui-dialog-body {
      padding: 0 0.29rem 0 0.08rem;
    }

    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.23rem;

      &-left {
        margin-top: -0.15rem;
        font-size: 0.12rem;

        > p {
          margin-bottom: 0.22rem;
        }

        div p {
          margin-bottom: 0.1rem;
        }
      }

      &-center {
        > p {
          margin-top: -0.1rem;
          margin-bottom: 0.16rem;
        }

        img {
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      &-right {
        > p {
          margin-bottom: 0.09rem;
        }

        img {
          width: 1.63rem;
          height: 1.63rem;
        }
      }

      &-line {
        width: 1px;
        height: 0.9rem;
        background-color: #edecf0;

        &--left {
          margin: 0 0.49rem 0 0.44rem;
        }

        &--right {
          margin: 0 0.4rem 0 0.43rem;
        }
      }
    }
  }

  &__online-customer {
    position: fixed;
    right: 0;
    cursor: pointer;
    transition: all 0.5s ease;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1350px) {
  .km-layout__header.layout-header {
    padding: 0 3%;
  }

  .km-layout .login-buttons {
    & .login-btn {
      padding: 0.08rem 0.2rem;
    }

    & .register-btn {
      padding: 0.08rem 0.18rem;
    }
  }
}

@media screen and (min-width: 300px) and (max-width: 1023px) {
  .km-layout {
    padding: 50px 0 0;

    &__header.layout-header {
      height: 50px;
      padding: 12px;
      z-index: 1001;
    }

    &__nav.layout-header__links-nav {
      display: none;
    }

    &__left,
    &__right {
      height: 100%;
    }

    &__left {
      & .intruction-entrance {
        display: none;
      }
    }

    &__right {
      & .login-buttons {
        & .login-btn,
        & .register-btn {
          font-size: 14px;
          height: 34.2px;
        }

        & .login-btn {
          width: 82px;
          padding: 6px 0;
          margin-right: 7px;
        }

        & .register-btn {
          width: 92px;
          padding: 6px 0;
        }
      }

      & .user-info {
        display: none;
      }

      & .wap-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        margin-left: 12px;
        width: 28px;
        height: 28px;
        border: 2px solid #a4a4a4;
        padding: 6px;

        & .short-line,
        & .long-line {
          height: 2px;
          background-color: #a4a4a4;
        }

        & .short-line {
          width: 70%;
        }

        & .long-line {
          width: 100%;
        }
      }
    }

    &__drawer {
      & .drawer-link {
        color: #000000;
        font-size: 16px;
        margin-bottom: 24px;
      }

      & .login-out-btn {
        position: absolute;
        bottom: 90px;
        height: 36px;
        font-size: 16px;
      }

      &-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        & .b-user-avatar {
          width: 65px;
          height: 65px;
          border-radius: 50%;
        }
      }
    }

    &__dialog {
      top: calc(50% + 25px);

      &-content {
        display: block;
        margin-top: 0;

        &-left > div,
        &-center img,
        &-right img {
          padding-left: 0.1rem;
        }

        &-line {
          width: 100%;
          height: 1px;
        }

        &-line--left {
          margin: 0.15rem 0 0.2rem;
        }

        &-line--right {
          margin: 0.1rem 0;
        }

        &-left,
        &-center,
        &-right {
          > p {
            margin-bottom: 0.15rem;
          }
        }

        &-left {
          margin-top: 0.15rem;
        }
      }

      .kui-dialog-header {
        padding-bottom: 0.65rem;
      }
    }

    .km-layout__dialog .kui-dialog-body {
      padding-left: 0;
    }
  }
}

@media screen and (min-width: 300px) and (max-width: 1203px) {
  .km-layout {
    &__online-customer {
      display: none;
    }
  }
}

.kz-ui-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  &__invisible {
    opacity: 0;
    z-index: -1;
  }

  &__mask {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,1);
    opacity: 0.6;
  }

  &__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: #ffffff;
    font-size: 0.16rem;
    color: #000000;
    width: 950px;
  }

  &__header {
    position: relative;
    padding: 16px;
    border-bottom: 1px solid #ededed;
    text-align: center;
    font-size: 0.22rem;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;

    & .close-symbol {
      position: absolute;
      right: 33px;
      top: 50%;
      margin-top: -15px;
      font-weight: bold;
      font-size: 20px;
      cursor: pointer;
    }
  }

  &__content {
    height: 600px;
  }
}
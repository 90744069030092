@import './styles/variable.less';
* {
    margin: 0;
    padding: 0;
    font-family: 'PingFangSC', 'Microsoft Yahei', 'Hiragino Sans GB', 'Lantinghei SC', 'Microsoft Sans Serif', 'WenQuanYi Micro Hei', sans-serif;
    box-sizing: border-box;
}

html {
  font-size: 68px;
}

@media screen and (max-width: 374px) {

}
@media screen and (min-width: 375px) and (max-width: 393px) {
  html {
      font-size: 70px;
  }
}

@media screen and (min-width: 394px) and (max-width: 412px) {
  html {
      font-size: 68px;
  }
}

@media screen and (min-width: 413px) and (max-width: 414px) {
  html {
      font-size: 70px;
  }
}

@media screen and (min-width: 415px) and (max-width: 480px) {
  html {
      font-size: 70px;
  }
}

@media screen and (min-width: 481px) and (max-width: 540px) {
  html {
      font-size: 70px;
  }
}

@media screen and (min-width: 541px) and (max-width: 640px) {
  html {
      font-size: 70px;
  }
}

@media screen and (min-width: 641px) and (max-width: 720px) {
  html {
      font-size: 70px;
  }
}

@media screen and (min-width: 721px) and (max-width: 960px) {
  html {
      font-size: 74px;
  }
}


@media screen and (min-width: 960px) and (max-width: 1025px) {
  html {
      font-size: 78px;
  }
}

@media screen and (min-width: 1026px) and (max-width: 1199px) {
  html {
    font-size: 85px;
  }
}

@media screen and (min-width: 1200px) {
  html {
      font-size: 100px;
  }
}


a {
    color: #fff;
    text-decoration: none;
}

ul {
    list-style: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
@blockName: c-online-customer;

.@{blockName} {
    &__dialog {
        padding: 0.23rem 0.32rem 0.68rem 0.31rem;
    
        .kui-dialog-header {
            padding: 0;
            padding-bottom: 0.55rem;
            height: 0;
            font-size: 0.2rem;
            color: #252a4b;
    
            i {
                margin: 0;
            }
        }
    
        .kui-dialog-body {
            padding: 0 0.29rem 0 0.08rem;
        }
    
        &-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 0.23rem;
        
            &-left {
                margin-top: -0.15rem;
                font-size: 0.12rem;
        
                > p {
                margin-bottom: 0.22rem;
                }
        
                div p {
                margin-bottom: 0.1rem;
                }
            }
        
            &-center {
                > p {
                    margin-top: -0.1rem;
                    margin-bottom: 0.16rem;
                }
        
                img {
                    width: 1.5rem;
                    height: 1.5rem;
                }
            }
        
            &-right {
                > p {
                    margin-bottom: 0.09rem;
                }
        
                img {
                    width: 1.63rem;
                    height: 1.63rem;
                }
            }
        
            &-line {
                width: 1px;
                height: 0.9rem;
                background-color: #edecf0;
        
                &--left {
                    margin: 0 0.49rem 0 0.44rem;
                }
        
                &--right {
                    margin: 0 0.4rem 0 0.43rem;
                }
            }
        }
    }
    
    &__img {
        position: fixed;
        right: 0;
        cursor: pointer;
        transition: all 0.5s ease;
    }

    &__customer-service {
        position: fixed;
        right: 38px;
        cursor: pointer;
        transition: all 0.5s ease;
        width: 123px;
    }
}

@media screen and (min-width: 300px) and (max-width: 1023px) {
    .@{blockName} {
        &__dialog {
            top: calc(50% + 25px);

            &-content {
                display: block;
                margin-top: 0;
        
                &-left > div,
                &-center img,
                &-right img {
                    padding-left: 0.1rem;
                }
        
                &-line {
                    width: 100%;
                    height: 1px;
                }
        
                &-line--left {
                    margin: 0.15rem 0 0.2rem;
                }
        
                &-line--right {
                    margin: 0.1rem 0;
                }
        
                &-left,
                &-center,
                &-right {
                    > p {
                        margin-bottom: 0.15rem;
                    }
                }
        
                &-left {
                    margin-top: 0.15rem;
                }
            }
    
            .kui-dialog-header {
                padding-bottom: 0.65rem;
            }

            .kui-dialog-body {
                padding-left: 0;
            }
        }
    }
}


@media screen and (min-width: 300px) and (max-width: 1203px) {
    .@{blockName} {
        &__img {
            display: none;
        }
    }
}
